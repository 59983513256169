import { switchAnatomy as parts } from "@chakra-ui/anatomy";
import { ComponentStyleConfig, SystemStyleObject } from "@chakra-ui/react";
import {
  calc,
  cssVar,
  mode,
  PartsStyleFunction,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";

const $width = cssVar("switch-track-width");
const $height = cssVar("switch-track-height");

const $diff = cssVar("switch-track-diff");
const diffValue = calc.subtract($width, $height);

const $translateX = cssVar("switch-thumb-x");
const baseStyleTrack: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  return {
    borderRadius: "full",
    p: "2px",
    width: "61px",
    height: [$height.reference],
    transitionProperty: "common",
    transitionDuration: "fast",
    bg: mode("gray.300", "whiteAlpha.400")(props),
    _focus: {
      boxShadow: "outline",
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
    },
    _checked: {
      bg: mode("primary", `${c}.200`)(props),
    },
  };
};

const baseStyleThumb: SystemStyleObject = {
  bg: "white",
  transitionProperty: "transform",
  transitionDuration: "normal",
  borderRadius: "inherit",
  width: [$height.reference],
  height: [$height.reference],
  _checked: {
    transform: `translateX(33px)`,
  },
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc($diff).negate().toString(),
    },
  },
  track: baseStyleTrack(props),
  thumb: baseStyleThumb,
});

const Switch: ComponentStyleConfig = {
  baseStyle,
  sizes: {
    lg: {
      container: {
        [$height.variable]: "28px",
        [$height.variable]: "28px",
      },
    },
  },
  defaultProps: {
    size: "lg",
  },
};
export const switchStyles = {
  components: {
    Switch,
  },
};

export const buttonStyles = {
  components: {
    Button: {
      variants: {
        "with-shadow": {
          boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)",
        },
        "with-shadow-secondary": {
          backgroundColor: "secondary",
          boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)",
        },
        secondary: {
          backgroundColor: "secondary",
        },
      },
      sizes: {
        md: {
          // height: "72px",
          fontSize: "xl",
        },
        lg: {
          height: "80px",
          fontSize: "2xl",
        },
        xl: {
          height: "108px",
          p: "40px",
          fontSize: "4xl",
        },
      },
    },
  },
};

import { MCP_STORAGE } from "@mcp/constants";
import { SessionMessageResponse } from "@mcp/services/src/services";
import { del, get, set } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import { StateCreator } from "zustand";
import { persist, StateStorage } from "zustand/middleware";

import { ChatSliceStore } from "@/interfaces/stores";

const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await get(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};

const createChatSlice: StateCreator<
  ChatSliceStore,
  [],
  [["zustand/persist", Partial<ChatSliceStore>]],
  ChatSliceStore
> = persist(
  (set, get) => ({
    mode: undefined,
    onChangeMode: (mode: "IL" | "casey") => set(() => ({ mode })),

    selectedPin: {} as SessionMessageResponse,
    isBotTyping: false,
    isTimeoutDuration: false,
    isOpenOverlay: false,

    setIsOpenOverlay: (isOpenOverlay: boolean) => set((state) => ({ isOpenOverlay })),
    setIsBotTyping: (isBotTyping: boolean) => set((state) => ({ isBotTyping })),
    setIsTimeoutDuration: (isTimeoutDuration: boolean) => set((state) => ({ isTimeoutDuration })),
    onSelectPinned: (selected) => set((state) => ({
        ...state,
        selectedPin: selected,
      })),
    removeSelectedPin: () => set(
        (state) => ({
          ...state,
          selectedPin: {} as SessionMessageResponse,
        }),
        true,
      ),
  }),
  {
    name: MCP_STORAGE,
    getStorage: () => storage,
  },
);

export default createChatSlice;

import { QueryDataFetchResponse } from "@mcp/interfaces";
import { UserResponse } from "@mcp/services/src/services";
import { useQuery, useQueryClient } from "react-query";

import { mApi } from "@/services";

export const useUser = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  const oldUser = queryClient.getQueryData<QueryDataFetchResponse<UserResponse>>(["getProfileControllerProfile2"]);

  const { data, ...rest } = useQuery(
    ["getProfileControllerProfile2"],
    mApi.v1.getProfileControllerProfile2,
    {
      retry: 0,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      enabled,
      onError: () => {
        queryClient.setQueryData<QueryDataFetchResponse<UserResponse>>(
          ["getProfileControllerProfile2"],
          // @ts-ignore
          { ...oldUser, data: undefined },
        );
      },
    },
  );
  const isLogged = !!data?.data;

  if (isLogged) {
    const fullName = data?.data?.profile?.firstName && data?.data?.profile?.lastName
      ? `${data?.data?.profile.firstName} ${data?.data?.profile.lastName}`
      : "";

    return {
      isLogged,
      ...rest,
      ...data?.data,
      profile: {
        ...data?.data?.profile,
        id: Number(data?.data?.profile?.id),
        fullName,
        secretKey: data?.data?.secretKey,
      },
    };
  }

  return {
    isLogged,
    ...rest,
    ...data?.data,
    profile: undefined,
  };
};

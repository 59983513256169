import { ComponentStyleConfig } from "@chakra-ui/react";

const Input: ComponentStyleConfig = {
  sizes: {
    lg: {
      field: {
        height: "52px",
      },
    },
  },
  variants: {},
  defaultProps: {
    size: "lg",
    focusBorderColor: "primary",
  },
};
export const inputStyles = {
  components: {
    Input,
  },
};

import { globalStyles } from "@/theme/styles";

export const caseyStyles = {
  ...globalStyles,
  colors: {
    ...globalStyles.colors,
    primary: "#177B57",
    secondary: "#177B57",
    green50: "#F0F5F3",
    green100: "#F1F5F0",
    green300: "#D3EEE4",
    green500: "#177B57",
    // green: {
    //   50: "#F0F5F3",
    //   100: "#F1F5F0",
    //   300: "#D3EEE4",
    //   500: "#177B57",
    // },
  },
};

import React, { ReactNode } from "react";

import { useDisclosure } from "@chakra-ui/react";
import { REFETCH_INTERVAL } from "@mcp/constants";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useQuery } from "react-query";

import { useUser } from "@/hooks/useUser";
import { mApi } from "@/services";

const ModalNotAuthorized = dynamic(() => import("@/components/ModalNotAuthorized"));

function AuthLoader({
  children,
  role,
  errorCode,
}: {
  children?: ReactNode;
  role?: "user" | "administrator";
  errorCode?: number
}) {
  const router = useRouter();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const { isLogged, isLoading } = useUser();

  React.useEffect(() => {
    (async () => {
      if (isLoading) return;

      if (!isLogged) {
        const callbackUrl = encodeURIComponent(window.location.href);
        await router.push(`${process.env.ACCOUNT_URL}/signin?callback_url=${callbackUrl}`);
      }

      // if (!data?.data?.hasCasebotPermission) {
      //   handleError(new Error("You are not authorized to access this page."));
      // }
    })();
  }, [isLoading, isLogged]);

  if (errorCode === 403) {
    return <ModalNotAuthorized isOpen={isOpen} onClose={onClose} />;
  }

  if (isLogged) return <div>{children}</div>;

  return null;
}

export default AuthLoader;

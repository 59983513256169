import {
  extendTheme,
  type ThemeConfig,
  withDefaultColorScheme,
} from "@chakra-ui/react";

import { CardComponent } from "./additions/Card";
import { badgeStyles } from "./components/badge";
import { buttonStyles } from "./components/button";
import { inputStyles } from "./components/input";
import { selectStyles } from "./components/select";
import { switchStyles } from "./components/switch";
import { breakpoints } from "./foundations/breakpoints";
import { font } from "./foundations/fonts";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const customTheme = ({
  globalStyles,
  colorScheme = "blue",
}: {
  globalStyles: {};
  colorScheme: "green" | "blue";
}) => {
  return extendTheme(
    { breakpoints, config },
    globalStyles,
    withDefaultColorScheme({ colorScheme }),
    font,
    buttonStyles,
    badgeStyles,
    inputStyles,
    selectStyles,
    switchStyles,
    CardComponent
  );
};

import create from "zustand";

import { AuthSliceStore, ChatSliceStore } from "@/interfaces/stores";
import createAuthSlice from "@/stores/createAuthSlice";
import createChatSlice from "@/stores/createChatSlice";

const useStore = create<ChatSliceStore & AuthSliceStore>()((...a) => ({
  ...createChatSlice(...a),
  ...createAuthSlice(...a),
}));

export default useStore;

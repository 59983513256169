import React, { useEffect, useState } from "react";

import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import { ErrorUnknown } from "@mcp/core";
import { AppAuthProps, OnErrorFetchResponse } from "@mcp/interfaces";
import { ErrorBoundary } from "react-error-boundary";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from "react-query";

import AuthLoader from "@/components/AuthLoader";
import useStore from "@/stores/useStore";
import { caseyStyles } from "@/theme/caseyStyles";
import { globalStyles } from "@/theme/styles";
import { customTheme } from "@/theme/theme";
import "@/assets/css/global.css";

const { ToastContainer, toast } = createStandaloneToast();

function MyApp({ Component, pageProps }: AppAuthProps) {
  const { auth } = Component;
  const [themeConfig, setThemeConfig] = useState(
    customTheme({ globalStyles, colorScheme: "blue" }),
  );
  const onChangeMode = useStore((state) => state.onChangeMode);
  const [errorCode, setErrorCode] = useState<number | undefined>(undefined);

  const [queryClient] = useState(
    () => new QueryClient({
      defaultOptions: {
        queries: {
          useErrorBoundary: (error: any) => {
            const err = error as OnErrorFetchResponse;
            return (
              Number(err?.status) >= 500
              || Number(err?.error?.statusCode) >= 500
            );
          },
          staleTime: 2000,
          refetchOnWindowFocus: true,
          refetchOnReconnect: true,
          retry: 0,
        },
        mutations: {
          useErrorBoundary: (error: any) => {
            const err = error as OnErrorFetchResponse;
            return (
              Number(err?.status) >= 500
              || Number(err?.error?.statusCode) >= 500
            );
          },
        },
      },
      queryCache: new QueryCache({
        onError: (error, query) => {
          const err = error as OnErrorFetchResponse;
          setErrorCode(err?.status);
          if (query.state.data !== undefined) {
            toast({
              status: "error",
              position: "bottom-right",
              title: "Something went wrong",
              description: err?.error?.message,
              isClosable: true,
              variant: "left-accent",
            });
          }
        },
      }),
    }),
  );

  useEffect(() => {
    const isCasey = window.location.host.includes("casey.mconsultingprep.com") || window.location.host.includes("localhost:3333");
    if (isCasey) {
      setThemeConfig(
        customTheme({
          globalStyles: caseyStyles,
          colorScheme: "green",
        }),
      );
      onChangeMode("casey");
    } else {
      setThemeConfig(
        customTheme({
          globalStyles,
          colorScheme: "blue",
        }),
      );
      onChangeMode("IL");
    }
  }, [onChangeMode]);

  return (
    <QueryClientProvider client={queryClient}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ChakraProvider theme={themeConfig}>
            <ErrorBoundary
              fallbackRender={({ resetErrorBoundary, error }) => (
                <ErrorUnknown message={error.message} reset={resetErrorBoundary} />
                )}
              onReset={reset}
            >
              {auth ? (
                <AuthLoader errorCode={errorCode}>
                  <Component {...pageProps} />
                  {/* <GlobalLoadingIndicator /> * */}
                </AuthLoader>
            ) : (
              <Component {...pageProps} />
            )}
            </ErrorBoundary>
            <ToastContainer />
          </ChakraProvider>
        )}
      </QueryErrorResetBoundary>
    </QueryClientProvider>
  );
}

export default MyApp;

import { ComponentStyleConfig } from "@chakra-ui/react";

const Select: ComponentStyleConfig = {
  sizes: {
    lg: {
      field: {
        height: "52px",
      },
    },
  },
  variants: {},
  defaultProps: {
    size: "lg",
  },
};
export const selectStyles = {
  components: {
    Select,
  },
};

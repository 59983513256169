export const badgeStyles = {
  components: {
    Badge: {
      variants: {
        primary: {
          color: "white",
          backgroundColor: "primary",
        },
        secondary: {
          color: "gray.333",
          backgroundColor: "secondary",
        },
        mint: {
          color: "gray.333",
          backgroundColor: "mint",
        },
      },
      sizes: {
        md: {
          width: "65px",
          height: "25px",
        },
      },
      baseStyle: {
        textTransform: "capitalize",
        borderRadius: "6px",
        padding: "5px 10px",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "15px",
      },
    },
  },
};

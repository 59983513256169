import { MCP_STORAGE } from "@mcp/constants";
import { StateCreator } from "zustand";
import { persist } from "zustand/middleware";

import { AuthSliceStore } from "@/interfaces/stores";

const createAuthSlice: StateCreator<
  AuthSliceStore,
  [],
  [["zustand/persist", Partial<AuthSliceStore>]],
  AuthSliceStore
> = persist(
  (set, get) => ({
    userInfo: undefined,
    setUserInfo: (userInfo) => set(() => ({ userInfo })),
  }),
  {
    name: MCP_STORAGE,
  },
);

export default createAuthSlice;

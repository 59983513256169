import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    transparent: "transparent",
    black: "#000000",
    white: "#ffffff",
    mint: "#D3EEE4",
    "blue-sky": "#4D9AF5",
    blue: {
      100: "#EFF5FF",
      200: "#ADD1FA",
      300: "#BEDEF9",
      400: "#4D9AF5",
      500: "#094F9F",
      600: "#094F9F",
    },
    primary: "#094F9F",
    "primary-light": "#ADD1FA",
    secondary: "#FFCB16",
    yellow: "#FFD647",
    orange: "#FF9800",
    disabled: "#E6E6E6",
    gray: {
      F5: "#F5F5F5",
      FB: "#FBFDFE",
      A0: "#A0A0A0",
      "333": "#333333",
      "1F": "#1f2733",
      "5A": "#5A5A5A",
      E6: "#E6E6E6",
      D9: "#D9D9D9",
      "0D9": "#000000d9",
      "073": "#00000073",
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: mode("white", "gray.800")(props),
        fontFamily: `'IBM Plex Sans', sans-serif`,
        color: "black",
      },
      button: {
        fontFamily: `Montserrat, sans-serif`,
      },
    }),
    heading: {
      fontFamily: `'IBM Plex Sans', sans-serif`,
    },
  },
  fonts: {
    heading: `'IBM Plex Sans', sans-serif`,
  },
};
